<template>
    <div>
        <base-stueck
                name="GedaechtnisWasser"
                path-name="GedaechtnisWasser"
                title="Gedächtnis des Wassers"
                author="Shelagh Stephenson"
                :bilder-anzahl="28"
        >
            <template #beschreibung>
              3 Schwestern, 1 Sarg, 1 Liebhaber,
              1 Ehemann und keine Hochzeit. Mit bissigem Wortwitz hinterfragt die Autorin eine
              Familienkonstellation,
              in der die Hinterbliebenen zu einer Beerdigung zusammenkommen. Die Geschwister sind so
              unterschiedlich
              wie ihre Erinnerungen an die gemeinsame Mutter. Spannend und makaber gestaltet sich das
              ungewöhnliche
              Familientreffen in einer anrührenden, absurd-witzigen Inszenierung,
              in der sich viele Zuschauer wiederfinden werden.
              <br><br>
              Im Jahre 2000 wurde das Stück mit dem Oliver-Award-Preis ausgezeichnet und mit viel
              Erfolg
              auf
              deutschen Bühnen gespielt.
            </template>
            <template #mitwirkende>
              <v-row>
                <v-col cols="12" lg="6">
                  <b style="color: #c00">Besetzung: </b><br><br>
                  <table>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Mary</td>
                      <td>Jana Lang</td>
                    </tr>
                    <tr>
                      <td>Catherine</td>
                      <td>Christin Geißler</td>
                    </tr>
                    <tr>
                      <td>Theresa</td>
                      <td>Katja Anita Peters</td>
                    </tr>
                    <tr>
                      <td>Vi</td>
                      <td>Carola Johnson</td>
                    </tr>
                    <tr>
                      <td>Frank</td>
                      <td>Ronaro Zuin-Lodron</td>
                    </tr>
                    <tr>
                      <td>Mike</td>
                      <td>Mario Hänel</td>
                    </tr>
                  </table>
                </v-col>
                <v-col cols="12" lg="6">
                  <b style="color: #c00">Mitwirkende: </b><br><br>
                  <table>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Regie</td>
                      <td>Anschi Prott</td>
                    </tr>
                    <tr>
                      <td>Licht</td>
                      <td>Mario Hänel</td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "GedaechtnisWasser",
        components: {BaseStueck},
        metaInfo: {
            title: "Gedächtnis des Wasser",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Gedächtnis des Wasser'}
            ]
        },
    }
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  font-size: small;
  padding: 8px;
}

tr:first-child {
  background-color: #eee;
}
</style>