var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","lg":"5"}},[_c('h3',{staticClass:"font-italic font-weight-light"},[_vm._v("Ich glaube an die "),_c('br'),_vm._v(" Unsterblichkeit "),_c('br'),_vm._v(" des Theaters."),_c('br'),_vm._v(" Es ist der seligste Schlupfwinkel für diejenigen, die ihre Kindheit heimlich in die Tasche gesteckt und sich damit auf und davon gemacht haben,"),_c('br'),_vm._v(" um bis an ihr Lebensende weiterzuspielen.")]),_c('br'),_c('p',{staticClass:"body-1"},[_vm._v("Max Reinhardt")])]),_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('small',[_vm._v("Für Details klicken")]),_c('v-row',{attrs:{"justify":_vm.$vuetify.breakpoint.lgAndUp ? 'start' : 'center'}},[_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/HelgesLeben"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_HelgesLeben.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Helges Leben")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/InfizierendeGefuehle"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_InfizierendeGefuehle.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("InfizierendeGefuehle")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Thunfischdosen"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Thunfischdosen.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Thunfischdosen")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/GedaechtnisWasser"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_GedaechtnisWasser.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Gedächtnis des Wasser")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 3,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/PlemPlem"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_PlemPlem.jpg","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Plem Plem")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/UngleichesPaar"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_UngleichesPaar.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Ein ungleiches Paar")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Dorian"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Dorian.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Das Bildnis von Dorian Gray")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Mueller"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Mueller.jpg","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Frau Müller muss weg")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Gerald"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Gerald.jpg","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Geralds Tod")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Luther"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Luther.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Martin Luther & Thomas Münzer")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Yvonne"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Yvonne.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Yvonne - Die Burgunderprinzessin")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/London_Suite"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_London_Suite.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("London Suite")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Geister"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Geister.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Haltestelle Geister")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Unterhaltungsschaden"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Schaden.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Unterhaltungsschaden")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Sommernachtstraum"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Sommernachtstraum.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Sommernachtstraum")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Hamlet"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Hamlet.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Jeder ist Hamlet")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Rosenbeet"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Rosenbeet.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Schau nicht unters Rosenbeet")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/1000"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_1000.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("1000 Tode")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Duerrenmatt"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Duerrenmatt.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Die Physiker")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"10","lg":"7"}},[_c('v-hover',{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 12: 6,"ripple":""}},[_c('router-link',{attrs:{"to":"was_war/Wahnsinn"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-radius":"4px"},attrs:{"src":"/images/Menu_Wahnsinn.png","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("Der nackte Wahnsinn")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }