<template>
  <v-row>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Bettina"
                   image-src="/Bilder/Akteure/Bettina.jpg"
      >Theater das ist die Arbeit mit mir selbst, mit dem Part, der sonst im Exil lebt.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Thomas"
                   image-src="/Bilder/Akteure/Thomas.jpg"
      >Theater ist einmalig: Stimmung und Energie jedes Einzelnen auf der Bühne wie im Publikum
        erzeugen
        unwiederholbare Momente.
        Gruppendynamik. Gefühle aller Art. Zeitensprünge. Zauber. Drama. Konzentration. Probenarbeit.
        Spaß.
        Gemeinsamer Erfolg. !!!Applaus!!!
        Achtung: Suchtgefahr!
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Doris"
                   image-src="/Bilder/Akteure/Doris.png"
      >Warum ich Theater spiele?<br><br>
        Ganz einfach,<br><br>
        Es macht mir wahnsinnig viel Spaß.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Rene"
                   image-src="/Bilder/Akteure/Rene.jpg"
      >Immer wieder etwas Neues ausprobieren, in andere Rollen schlüpfen, Blickwinkel ändern und somit
        vielleicht
        auch etwas über sich selbst lernen.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Yuko"
                   image-src="/Bilder/Akteure/yuko.JPG"
      >Wir wissen wohl, was wir sind, aber nicht, was wir werden können<br>
        <small>(Shakespeare-"Hamlet")</small></akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Heidi"
                   image-src="/Bilder/Akteure/Heidi.jpg"
      >Dem inneren Zensor mal den Stinkefinger zeigen und eine ganze Palette an möglichen
        Gefühlszuständen durchleben zu können/dürfen/müssen ist meine größte Faszination und
        Herausforderung.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Nicki"
                   image-src="/Bilder/Akteure/Nicki.jpg"
      >Das abtauchen, sich einlassen auf; erforschen verschiedener menschentypen in allen Facetten.<br>
        Dinge tun, die man sonst nie tun würde - Urlaub von sich selbst nehmen,<br>
        mit anderen sich auf die Reise begeben und sich dadurch doch selbst entdecken!
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Stefan"
                   image-src="/Bilder/Akteure/Stefan.jpg"
      >Ich spiele seit 2005 Laientheater. Theaterspielen gehört neben Lesen, Schreiben, Radfahren und
        Wandern zu meinen Freizeithobbys und Leidenschaften. Nach einer zweijährigen corona-bedingten Zwangspause
        vom Theaterspielen bin ich im Oktober 2021 bei Heidekraut gelandet.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Marina"
                   image-src="/Bilder/Akteure/marina.png"
      >Es ist wichtig Gesicht zu zeigen, aber am Text muss ich noch feilen... ;)
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Sousan"
                   image-src="/Bilder/Akteure/Sousan.jpeg"
      > Ich spiele Theater, weil es sich echt anfühlt. <br>
        Weil es Spaß macht und süchtig macht
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Mario"
                   image-src="/Bilder/Akteure/placeholder_man.png"
      >Große Zufriedenheit entsteht, wenn sich szenische Arbeiten, Geräusche, Musik und Licht langsam
        (oder
        schneller) zu einem funktionierenden Theaterabend zusammenfügen...
        ...vielleicht bin ich auch bloß so eine "Rampensau", die sich auch noch gern um die Technik
        kümmert.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Andreas"
                   image-src="/Bilder/Akteure/placeholder_man.png"
      >
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Carola"
                   image-src="/Bilder/Akteure/placeholder_woman.png"
      >
      </akteur-card>
    </v-col>
  </v-row>
</template>

<script>

import AkteurCard from "./AkteurCard";

export default {
  name: "AkteureAktiv",
  components: {AkteurCard},
}
</script>

<style scoped>

</style>