<template>
    <div>
        <base-stueck
                name="HelgesLeben"
                path-name="HelgesLeben"
                title="Helges Leben"
                subtitle="Eine absurd groteske Komödie von Sibylle Berg"
                :bilder-anzahl="16"
        >
            <template #beschreibung>
              ....in nicht allzu ferner Zukunft....<br>
              Die Tiere haben sich weiterentwickelt und die Menschen überlebt.<br>
              Gott und Tod müssen ihr Überleben durch Privat-Shows sichern.<br>
              So buchen ein Tapir und seine Gattin bei ihnen ein
              „ganz normales Menschenleben“ und bekommen - Helge - <br>
              Ein Leben, das von ständigen Misserfolgen begleitet wird,
              während die Angst stets als treue Gefährtin an seiner Seite verweilt.
              Bis Helge auf Tina trifft und alles könnte nicht schöner sein<br>
              .....wäre da nicht Helges Angst.
              <br><br>
              Mit leichten bis bissigen Tönen beschreibt Sibylle Berg ein Szenario,
              welches absurd vertraut erscheint und das Publikum mit groteskem Humor unterhält.
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "HelgesLeben",
        components: {BaseStueck},
        metaInfo: {
            title: "Helges Leben",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Helges Leben'}
            ]
        },
    }
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  font-size: small;
  padding: 8px;
}

tr:first-child {
  background-color: #eee;
}
</style>