<template>
    <div>
        <base-stueck
                name="Dorian"
                path-name="Dorian"
                title="Das Bildnis des Dorian Gray"
                author="Oscar Wilde"
                :bilder-anzahl="28"
                bilder-format="png"
        >
            <template #beschreibung>
                "Das Bild wird unverändert bleiben! Und ich? Ich wünschte es wäre umgekehrt!!",<br>
                ruft Dorian Gray aus, als er das Foto sieht, das Basil Hallward von
                ihm gemacht hat. Sogar seine Seele würde er dafür geben.<br>
                Dorians Wunsch wird erfüllt: Fortan verändert sich nichts an der makellosen Schönheit
                seines Körpers. <br>
                Stattdessen zeigt sein Abbild mit wachsender Grausamkeit
                die Spuren seines Inneren ...
            </template>
            <template #mitwirkende>
                <v-img src="/Bilder/Dorian/Dorian_Mitwirkende.png" max-width="400"></v-img>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Dorian",
        components: {BaseStueck},
        metaInfo: {
            title: "Dorian Gray",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Das Bildnis von Dorian Gray'}
            ]
        },
    }
</script>

<style scoped>

</style>