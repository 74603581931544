<template>
  <div>
    <v-card
        to="/Proben">
      <v-card-title>
        <v-img
            src="/images/Mitglieder_Aufruf2.png" alt="MitgliederAufruf">
        </v-img>
      </v-card-title>
      <v-card-text>
        <small>Für Details klicken</small>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "AnwaerbungCard"
}
</script>