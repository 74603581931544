<template>
    <div>
        <div>
            <h2>Impressum</h2>
            <h3>Angaben gemäß § 5 TMG:</h3>
            <p class="body-1">
                Theatergruppe Heidekraut e.V.<br/>
                c/o Thomas Loichinger<br/>
                Robinienstr. 33a<br>
                80935 München<br>
                <br/>
            </p>
            <h3>Kontakt:</h3>
            <p class="body-1">
                Mail: info@theater-heidekraut.de
            </p><br/>
            <h3>Haftung für Inhalte</h3>
            <p class="body-1">
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                umgehend entfernen.
            </p><br/>
            <h3>Haftung für Links</h3>
            <p class="body-1">Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
                Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
                der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
                Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
                auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Links umgehend entfernen.
            </p><br/>
            <h3>Urheberrecht</h3>
            <p class="body-1">
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
                jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads
                und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
                einen
                entsprechenden Hinweis.
                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p><br/>
            <h3>Datenschutzerklärung</h3><br>

            <h4>1. Datenschutz auf einen Blick</h4><br>
            <h5>Allgemeine Hinweise</h5>
            <p class="body-1">
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                passiert,
                wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
                identifiziert
                werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
                aufgeführten Datenschutzerklärung.
            </p><br>

            <h5>Datenerfassung auf unserer Website</h5>
            <p class="body-1">
                <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong>
                <br>
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können
                Sie
                dem
                Impressum dieser Website entnehmen.
                <br>
                <strong>Wie erfassen wir Ihre Daten?</strong>
                <br>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
                Daten
                handeln, die Sie in ein Kontaktformular eingeben.
                <br>
                Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                allem
                technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung
                dieser
                Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                <br>
                <strong>Wofür nutzen wir Ihre Daten?</strong>
                <br>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                Daten
                können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                <br>
                <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
                <br>
                Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder
                Löschung
                dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
                jederzeit
                unter
                der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
                zuständigen Aufsichtsbehörde zu.
            </p><br>
            <h5>Analyse-Tools und Tools von Drittanbietern</h5>
            <p class="body-1">
                Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
                allem
                mit
                Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel
                anonym;
                das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen
                oder
                sie
                durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der
                folgenden Datenschutzerklärung.
                <br>
                Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser
                Datenschutzerklärung informieren.
            </p><br>
            <h4>2. Allgemeine Hinweise und Pflichtinformationen</h4><br>
            <h5>Datenschutz</h5>
            <p class="body-1">
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                dieser
                Datenschutzerklärung.
                <br>
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
                Daten
                sind
                Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
                erläutert,
                welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
                geschieht.
                <br>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                nicht
                möglich.
            </p><br>
            <h5>Hinweis zur verantwortlichen Stelle</h5>
            <p class="body-1">
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br>
                <br>
                Theatergruppe Heidekraut e.V.<br/>
                c/o Christian Ahrens<br/>
                Hugo-Brunninger-Straße 17<br>
                82216 Maisach<br>
                <br>
                E-Mail: info@theater-heidekraut.de<br>
                <br>
                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
                über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
                entscheidet.
            </p><br>
            <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
            <p class="body-1">
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                bereits
                erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
                Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            </p><br>
            <h5>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h5>
            <p class="body-1">
                Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen
                Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
                Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der
                Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:<br>
                <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">Kontaktdaten
                    BFDI</a>
            </p><br>
            <h5>Recht auf Datenübertragbarkeit</h5>
            <p class="body-1">
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                aushändigen
                zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen,
                erfolgt
                dies nur, soweit es technisch machbar ist.
            </p><br>
            <h5>SSL- bzw. TLS-Verschlüsselung</h5>
            <p class="body-1">
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
                Beispiel
                Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
                TLS-Verschlüsselung.
                Eine
                verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf
                “https://”
                wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                <br>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln,
                nicht
                von
                Dritten mitgelesen werden.
            </p><br>
            <h5>Auskunft, Sperrung, Löschung</h5>
            <p class="body-1">
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                Auskunft
                über
                Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
                Datenverarbeitung
                und
                ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
                zum
                Thema
                personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
                wenden.
            </p><br>
            <h5>Widerspruch gegen Werbe-Mails</h5>
            <p class="body-1">
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
                ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber
                der
                Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
                Werbeinformationen, etwa durch Spam-E-Mails, vor.
            </p><br>
            <h4>3. Datenerfassung auf unserer Website</h4><br>
            <h5>Cookies</h5>
            <p class="body-1">
                Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
                Schaden
                an
                und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
                sicherer zu
                machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
                speichert.
                <br>
                Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende
                Ihres
                Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese
                löschen.
                Diese
                Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                <br>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                Cookies
                nur
                im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das
                automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von
                Cookies
                kann
                die Funktionalität dieser Website eingeschränkt sein.
                <br>
                Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
                bestimmter,
                von
                Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6
                Abs. 1
                lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
                Cookies
                zur
                technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B.
                Cookies
                zur
                Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert
                behandelt.
            </p><br>
            <h5>Server-Log-Dateien</h5>
            <p class="body-1">
                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                Server-Log-Dateien,
                die
                Ihr Browser automatisch an uns übermittelt. Dies sind:<br>
                <br>
                Browsertyp und Browserversion<br>
                verwendetes Betriebssystem<br>
                Referrer URL<br>
                Hostname des zugreifenden Rechners<br>
                Uhrzeit der Serveranfrage<br>
                IP-Adresse<br>
                Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                <br>
                Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur
                Erfüllung
                eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
            </p><br>
            <h5>Kontaktformular</h5>
            <p class="body-1">
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
                inklusive
                der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
                Anschlussfragen
                bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                <br>
                Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
                Grundlage
                Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu
                reicht
                eine
                formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                <br>
                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                auffordern,
                Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
                abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
                Aufbewahrungsfristen – bleiben unberührt.
            </p><br>
            <h4>4. Plugins und Tools</h4><br>
            <h5>Facebook</h5>
            <p class="body-1">
                Auf der unserer Homepage verwenden wir das Facebook-Plugin zur Verlinkung und Darstellung unserer
                Facebook-Präsens.
                Hierfür gelten die Datenschutzerklärung und Nutzungsbedingungen von Facebook:
                <a href="https://www.facebook.com/policy.php">Facebook Policies</a>
            </p>
            <h5>Google Maps</h5>
            <p class="body-1">
                Im Was-Ist-Bereich auf unserer Homepage ist als Routenplaner Google Maps implementiert. Hierfür gelten
                die
                Datenschutzerklärung und Nutzungsbedingungen von Google:
                <a href="https://policies.google.com/privacy?hl=de&gl=dehttps://policies.google.com/privacy?hl=de&gl=de">Google
                    Policies</a>
            </p>
            <h5>Google Analytics</h5>
            <p class="body-1">
                Google Analytics wird verwendet, um das Verhalten der Nutzer auf der Website zu analysieren.
                Dabei wird die IP-Adresse von jedem Nutzer anonymisiert, damit das Verhalten nicht einer einzelnen
                Person zuordenbar ist. <br>
                Zusätzlich gelten die Datenschutzerklärung und Nutzungsbedingungen von Google:
                <a href="https://policies.google.com/privacy?hl=de&gl=dehttps://policies.google.com/privacy?hl=de&gl=de">Google
                    Policies</a>
            </p>
            <p v-if="trackingIsActive" class="body-1">
                Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link
                klicken. Es wird ein Opt-Out-Cookie gesetzt, dass das Erfassung Ihrer Daten bei zukünftigen Besuchen
                dieser Website verhindert:
                <br>
                <v-btn class="mt-5" small @click="gaOptout">Google Analytics deaktivieren</v-btn>
            </p>
            <small v-else style="color: #cc0000">
                Sie haben der Erfassung Ihrer Daten mittels Opt-Out-Cookie wiedersprochen.
            </small>
        </div>
    </div>
</template>

<script>
    const gaProperty = 'UA-164067349-1';
    const disableStr = 'ga-disable-' + gaProperty;

    export default {
        name: "Impressum",
        metaInfo: {
            title: "Impressum",
            meta: [
                {name: 'description', content: 'Unser Impressum und die Datenschautzerklärung'}
            ]
        },
        mounted() {
            this.trackingIsActive = document.cookie.indexOf(disableStr + '=true') === -1
        },
        data() {
            return {
                trackingIsActive: true
            }
        },
        methods: {
            gaOptout() {
                document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
                window[disableStr] = true;
                this.trackingIsActive = false;
            }
        }
    }
</script>

<style scoped>

</style>