<template>
    <div>
        <v-row>

            <v-col cols="12" lg="4">
                <v-img
                        :src="'/Bilder/' + pathName + '/' + name + '_Card.' + bilderFormat"
                        width="100%"
                        class="elevation-1"></v-img>
            </v-col>
            <v-col cols="12" lg="7" class="text-end">
                <h1 style="color: #c00">{{title}}</h1>
                <h2 v-if="subtitle"><em>{{subtitle}}</em></h2>
                <h2 v-if="author"><em>von {{author}}</em></h2>
                <br><br>
                <p style="font-size: 18px">
                    <slot name="beschreibung"></slot>
                </p>
            </v-col>
        </v-row>
        <br><br>
        <div>
            <slot name="mitwirkende"></slot>
        </div>
        <div v-if="bilderAnzahl && bilderAnzahl > 0" class="mt-5">
            <h1>Bilder</h1>
            <p class="body-1">(Zum Vergrößern auf das Bild klicken)</p>

            <v-row style="padding-top: 10px; padding-bottom: 5px">

                <v-col :key="n" v-for="n in bilderAnzahl" cols="12" lg="4">
                                <v-img :src="'/Bilder/' + pathName + '/' + name + '_' + n + '.' + bilderFormat"
                                     style="width:100%;cursor:pointer"
                                     @click="onClick(n)"
                                     class="rounded-corners hover-opacity elevation-1">
                                </v-img>
                </v-col>

            </v-row>
        </div>
        <br>
        <div v-if="hasFlyer || hasPlakat">
            <b style="color: #c00">Downloads: </b><br>
            <v-btn v-if="hasFlyer" color="primary"><a
                    style="color: white !important;"
                    :href="'/Downloads/' + name + '_Flyer.pdf'"
                    download="Flyer.pdf">Flyer</a></v-btn>
            <v-btn v-if="hasPlakat" color="primary" class="ml-2"><a
                    style="color: white !important;"
                    :href="'/Downloads/' + name + '_Plakat.pdf'"
                    download="Plakat.pdf">Plakat</a></v-btn>
        </div>

        <!-- Modal Window for images -->
        <v-dialog v-model="imgDialog">
            <v-img @click="imgDialog=false" :src="imgDialogSrc" style="width:100%"></v-img>
        </v-dialog>
    </div>
</template>

<script>

    export default {
        name: "BaseStueck",
        props: {
            name: String,
            pathName: String,
            title: String,
            author: String,
            subtitle: String,
            bilderAnzahl: Number,
            bilderFormat: {
                type: String,
                default: 'jpg'
            },
            hasFlyer: {
                type: Boolean,
                default: true
            },
            hasPlakat: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                imgDialog: false,
                imgDialogSrc: ''
            }
        },
        methods: {
            onClick(index) {
                this.imgDialogSrc = '/Bilder/' + this.pathName + '/' + this.name + '_' + index + '.' + this.bilderFormat;
                this.imgDialog = true;
            }
        }
    }
</script>