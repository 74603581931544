<template>
    <div>
        <base-stueck
                name="PlemPlem"
                path-name="PlemPlem"
                title="Plem Plem"
                author="Laurent Baffie"
                :bilder-anzahl="18"
        >
            <template #beschreibung>
                Sechs Patienten mit unterschiedlichen Zwangsstörungen verbringen zusammen mehrere Stunden im
                Wartezimmer eines Psychotherapeuten. Der berühmte Dr. Stern wurde am Flughafen aufgehalten.
                Da bleiben Streitigkeiten und gegenseitiges Unverständnis nicht aus. Ob Tourette-Syndrom oder
                Bakterienphobie, ob Symmetriezwang, religiöser Tick, zwanghaftes Rechnen oder Wiederholen von
                Sätzen, jeder findet die Macken der anderen ziemlich unerträglich. Aber was hilft es, wenn
                man monatelang auf diesen Termin gewartet hat. Also versuchen alle, durchzuhalten und gemeinsam
                das Beste daraus zu machen.<br>
                Laurent Baffie hat eine durchgeknallte französische Komödie zum Thema Zwangsstörungen geschaffen,
                mit viel Respekt für menschliche Schwächen. Die Inszenierung der Theatergruppe Heidekraut
                unter der Regie von Anne Hubert-Roth lädt ein zu einem Perspektivenwechsel und wirbt für mehr
                Toleranz für die großen und kleinen Probleme der anderen. Zwischendurch darf auch kräftig gelacht
                werden. Denn seien wir mal ehrlich, wer ist nicht ein bisschen „plemplem“?
            </template>
            <template #mitwirkende>
                <v-img src="/Bilder/PlemPlem/PlemPlem_Mitwirkende.png" max-width="400"></v-img>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "PlemPlem",
        components: {BaseStueck},
        metaInfo: {
            title: "Plem Plem",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Plem Plem'}
            ]
        },
    }
</script>

<style scoped>

</style>