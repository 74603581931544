<template>
    <div>
        <base-stueck
                name="Rosenbeet"
                path-name="Rosenbeet"
                title="Schau nicht unters Rosenbeet"
                author="Norman Robbins"
                :bilder-anzahl="3"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Die Söhne und Töchter des verhassten Henk wollen end- lich ihr Erbe antreten. Haupterbin ist allerdings eine
                ihnen unbekannte Romanautorin, die tatsächlich aufkreuzt. Kein Wunder, dass jede und jeder der Henks über-
                legt, wie man die Dame am besten los wird. Doch bald ist sie nicht die einzige Leiche. Ein erbarmungsloses
                Zehn-kleine-Negerlein-Spiel beginnt und der Mörder ist garantiert nicht der Gärtner ....
            </template>
            <template #mitwirkende>
                <strong>Es spielten:</strong><br/>
                Christian Ahrens<br/>
                Ursula Baier<br/>
                Florian Bleikert<br/>
                Klaus Busskamp<br/>
                Peter Heeren<br/>
                Gerlinde Köstler-Dietl<br/>
                Doris von Krusenstjern<br/>
                Heidi Rohde<br/>
                Anna Scharlipp<br/>
                Ulrike Schiemenz<br/><br/><br/>

                <strong>Regie:</strong><br/>
                Gudrun Skupin
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Rosenbeet",
        components: {BaseStueck},
        metaInfo: {
            title: "Rosenbeet",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Schau nicht unters Rosenbeet'}
            ]
        },
    }
</script>

<style scoped>

</style>