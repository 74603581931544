<template>
    <div>
        <div class="text-center mb-5">
            <router-link to="aktiv" title="Akteure - vorige Seite"> &lt;</router-link>

            <router-link to="aktiv" title="Akteure - Aktive Spieler" :style="aktiv?'color:#cf160d':''">Aktiv
            </router-link>
            <router-link to="passiv" title="Akteure - Passive Mitglieder" :style="!aktiv?'color:#cf160d':''">Passiv
            </router-link>

            <router-link to="passiv" title="Akteure - nächste Seite"> &gt;</router-link>
        </div>
        <akteure-aktiv v-if="aktiv"></akteure-aktiv>
        <akteure-passiv v-if="!aktiv"></akteure-passiv>
    </div>
</template>

<script>

    import AkteureAktiv from "../components/akteure/aktiv";
    import AkteurePassiv from "../components/akteure/passiv";

    export default {
        name: "Akteure",
        components: {AkteurePassiv, AkteureAktiv},
        metaInfo: {
            title: "Akteure",
            meta: [
                {name: 'description', content: 'Aus welchen Akteuren besteht unser Verein'}
            ]
        },
        data() {
            return {
                aktiv: true
            }
        },
        mounted() {
            this.setAktiv()
        },
        watch: {
            '$route.params.aktiv': function () {
                this.setAktiv();
            }
        },
        methods: {
            setAktiv() {
                this.aktiv = this.$route.params.aktiv === 'aktiv'
            }
        }

    }
</script>

<style scoped>

</style>