<template>
    <div>
        <base-stueck
                name="Wahnsinn"
                path-name="Wahnsinn"
                title="Der nackte Wahnsinn"
                author="Michael Frayn"
                :bilder-anzahl="4"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Das Beispiel für ein großartiges Theater-auf-dem-Theater-Stück!<br/><br/>
                Benötigt werden: neun Schauspieler, die im "nackten Wahnsinn" neun Schauspieler spielen, die im Stück "Spaß
                muss sein" zehn Schauspieler spielen. Wichtig sind außerdem viele Türen, Ölsardinen, ausreichend Whisky, ein
                Feuerwehrbeil und – Darsteller mit unbändiger Lust auf diese irrwitzige Screwball- Comedy.
            </template>
            <template #mitwirkende>
                <strong>Es spielten:</strong><br/>
                Florian Bleikert / Silke Bleikert <br/>
                Veronika Herrmann<br/>
                Margret Kipphan<br/>
                Doris von Krusenstjern <br/>
                Gerhard Peipp<br/>
                Gabriele Scheuchenpflug <br/><br/>
                Regie: Anna Horn
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Wahnisnn",
        components: {BaseStueck},
        metaInfo: {
            title: "Nackte Wahnsinn",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Der nackte Wahnsinn'}
            ]
        },
    }
</script>

<style scoped>

</style>