<template>
    <div>
        <base-stueck
                name="Sommernachtstraum"
                path-name="Sommernachtstraum"
                title="Ein Sommernachtstraum"
                author="William Shakespeare"
                :bilder-anzahl="6"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Zu dieser wohl bekanntesten, beliebtesten und meist gespielten (Liebes-) Komödie sei – statt einer
                Inhaltsangabe – Jan Kott zitiert:<br/>
                "Der Sommernachtstraum ist das erotischste von allen Shakespeare-Stücken. Und wohl in keiner Tragödie
                oder
                Komödie ist die Erotik so brutal wie hier."
            </template>
            <template #mitwirkende>
                <strong>Es spielten:</strong><br/>
                Christian Ahrens<br/>
                Ursula Baier<br/>
                Anton Engelmeier<br/>
                Hans-Christian Hinterberger <br/>
                Joachim Klingemann <br/>
                Gerlinde Köstler-Dietl<br/>
                Doris von Krusenstjern<br/>
                Thomas Loichinger <br/>
                Antonio Lomuscio<br/>
                Gerhard Peipp<br/>
                Heidi Rohde<br/>
                Gabriele Scheuchenpflug <br/>
                Ulrike Schiemenz<br/><br/>
                <strong>Regie:</strong> Gudrun Skupin
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Sommernachtstraum",
        components: {BaseStueck},
        metaInfo: {
            title: "Sommernachtstraum",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Sommernachtstraum'}
            ]
        },
    }
</script>

<style scoped>

</style>