import Vue from 'vue'
import vuetify from './plugins/vuetify';
import router from './router/index'
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import App from './App.vue'

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router: router,
  render: h => h(App)
}).$mount('#app');