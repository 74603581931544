<template>
    <v-app>
        <app-menu v-if="showMenu"></app-menu>
        <v-main>
            <v-container fluid>
                <v-fade-transition>
                    <router-view></router-view>
                </v-fade-transition>
            </v-container>
        </v-main>
    </v-app>
</template>


<script>
    import AppMenu from './components/AppMenu'

    const TITLE_POSTFIX = "Heidekraut - Theater Spektakel München";

    export default {
        name: 'App',
        components: {AppMenu},
        metaInfo: {
            title: '',
            titleTemplate: (titleChunk) => {
                // If undefined or blank then we don't need the hyphen
                return titleChunk ? `${titleChunk} | ` + TITLE_POSTFIX : TITLE_POSTFIX;
            }
        },
        data() {
            return {
                showMenu: false
            }
        },
        mounted() {
            this.checkMenu()
        },
        watch: {
            $route() {
                this.checkMenu();
            }
        },
        methods: {
            checkMenu() {
                this.showMenu = this.$route.path !== '/'
            }
        }
    }
</script>
