<template>
    <v-card>
        <v-row>
            <v-col class="py-0" cols="6">
                <v-img :src="imageSrc"
                       :alt="name"
                       style="border-top-left-radius: 4px; border-bottom-left-radius: 4px"
                       height="100%"></v-img>
            </v-col>

            <v-col cols="6">
                <v-row
                        class="flex-column ma-0"
                        justify="start">
                    <v-col class="pa-0">
                        <h4><b>{{name}}</b></h4>
                    </v-col>
                    <v-col class="py-0 pl-0">
                        <p class="caption">
                            <slot></slot>
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: "AkteurCard",
        props: {
            name: String,
            imageSrc: String
        }
    }
</script>

<style scoped>

</style>