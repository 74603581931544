<template>
    <div>
        <base-stueck
                name="Geister"
                path-name="Geister"
                title="Haltestelle. Geister."
                author="Helmut Krausser"
                :bilder-anzahl="13"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Durchgeknallte Großstadtgestalten auf der Suche nach dem Glück finden Liebe, Leere, Leidenschaft,
                Abenteuer,
                Gewalt, Perversion, Rausch, Hass, Drogen, Hinterlist, Verbrechen, intergalaktische Überfälle,
                Zwischenwelten
                -
                und manche auch den Tod.<br><br>
            </template>
            <template #mitwirkende>
                <strong>Es spiel(t)en:</strong><br>
                Christian Ahrens<br>
                Helmut Birk<br>
                Josefine Boldewin<br>
                Karl Brida<br>
                Dorothea Dittrich<br>
                Anton Englmeier<br>
                Christiane Hedtkamp<br>
                Hans-Christian Hinterberger<br>
                Gerlinde Köstler-Dietl<br>
                Doris von Krusenstjern <br>
                Barbara Kuklinski <br>
                Thomas Loichinger <br>
                Gerhard Peipp<br>
                Heidi Rohde <br>
                Ulrike Schiemenz <br>
                <br><br>
                <strong>Regie:</strong><br>
                Gudrun Skupin<br>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Geister",
        components: {BaseStueck},
        metaInfo: {
            title: "Haltestelle Geister",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Haltestelle Geister'}
            ]
        },
    }
</script>

<style scoped>

</style>