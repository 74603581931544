<template>
    <div>
        <div class="intro">
            <router-link to="Start">
                <v-img eager
                       src="/images/Heidekraut_Logo.png"
                       width="100%"></v-img>
            </router-link>
        </div>

        <div class="skip">
            <router-link to="Start">Intro überspringen</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Intro",
        data() {
          return {
              timer: undefined,
          }
        },
        mounted() {
            this.weiterleitung("Start", "5");
        },
        beforeDestroy() {
            clearTimeout(this.timer)
        },
        methods: {
            weiterleitung(a, b) {
                var c = (b + "000") * 1;
                this.timer = setTimeout(() => this.doIt(a), c);
            },

            doIt(a) {
                this.$router.push(a);
            }
        }
    }
</script>

<style scoped>

    .intro {
        -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 4s; /* Firefox < 16 */
        -ms-animation: fadein 4s; /* Internet Explorer */
        -o-animation: fadein 4s; /* Opera < 12.1 */
        animation: fadein 4s;
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

</style>