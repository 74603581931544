<template>
  <v-row>
    <v-col cols="12" lg="5" class="text-end">
      <v-img
          class="elevation-1"
          src="/Bilder/MachMit/machmit_card.jpg" alt="MitgliederAufruf">
      </v-img>
    </v-col>
    <v-col cols="12" lg="5">
      <br>
      <h1>Aktuelle Workshops</h1>
      <br>

      <h2>Stay tuned....</h2>
      <p class="body-1 pt-5">
        Aufgrund der aktuellen Produktion sind zur Zeit keine Workshops geplant. Es werden zukünftig jedoch wieder <br>
        Neue stattfinden. Daher schaue bitte später noch einmal rein, um nichts zu verpassen.<br>
        Gerne kannst du uns auch auf <a href="https://www.facebook.com/TheatergruppeHeidekraut" target="_blank">Facebook</a>
         | <a href="https://www.instagram.com/theatergruppeheidekraut/" target="_blank">Instagram</a> folgen oder unseren
        <router-link to="/Kontakt">Newsletter</router-link> abonnieren, wenn du nichts verpassen möchtest.<br><br>
      </p>

      <br><v-divider></v-divider><br>
      <h1>Interesse am Verein</h1>
      <p class="body-1 pt-5">

        <!--
        Doch die Proben zu den neuen Stücken laufen schon. D.h., dass alle Rollen besetzt sind.
        So müssen wir Sie an dieser
        Stelle leider auf <b>Sommer/Herbst 2021</b> vertrösten.<br>
        Bis dahin haben sich eventuell wieder freie Plätze und Möglichkeiten zum Mitmachen ergeben.
        -->

        <!--
        Da können Sie gern reinschnuppern, mitmachen, sich ausprobieren und uns kennenlernen.<br>
        Parallel zu diesem Training suchen wir dann auch nach neuen Stücken.
        <br><br>
        <b>Generell:</b><br>
        -->

        Du bist spielwütig und hast Lust auf tolle Aufführungen mit viel Spass?
        <br><br>
        Du freust dich auf eine professionelle Regie?
        <br><br>
        Du freust dich auf ein professionelles Schauspiel- und Improtraining?
        <br><br>
        Du bist nicht spielwütig aber hast Lust, dich „irgendwie“ im Theater einzubringen?
        <br><br>

        <b>WUNDERBAR</b> - dann melde dich
        <router-link to="/Kontakt">HIER</router-link>
        !
      </p>
      <br><br>
      <h4>Mitglied werden</h4>
      <p class="body-1 pt-5">
        Wenn Du dich für' s Mitspielen oder Mitmachen entscheidest, solltest Du Mitglied bei uns werden.<br><br>
        Unsere Mitgliedsbeiträge:<br><br>

      </p>
      <table style="border-spacing: 1em">
        <tr>
          <td><b>Normal</b></td>
          <td>80 Euro pro Quartal</td>
        </tr>
        <tr>
          <td><b>Erm&auml;ßigt</b></td>
          <td>50 Euro pro Quartal</td>
        </tr>
        <tr>
          <td><b>Passiv</b></td>
          <td>40 Euro pro Jahr</td>
        </tr>
      </table>

      <br/>
      Wir freuen uns auf Dich!
      <br/><br/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "Proben",
  metaInfo: {
    title: "Proben",
    meta: [
      {name: 'description', content: 'Alle Informationen für die, die mitmachen wollen'}
    ]
  },
}
</script>

<style scoped>
a {
  text-decoration: underline;
}
</style>
