<template>
    <div>
        <base-stueck
                name="Frau_Mueller"
                path-name="FrauMuellerMussWeg"
                title="Frau Müller muss weg!"
                author="Lutz Hübner"
                :bilder-anzahl="23"
        >
            <template #beschreibung>
                Es gibt Dinge, da hört der Spaß auf: das Übertrittszeugnis, das für die weitere Schullaufbahn der Kinder
                entscheidend ist. Fünf entschlossene Mütter und Väter sitzen auf Kinderstühlchen, bereit, dem Feind ins Auge
                zu sehen. Sachlichkeit und Objektivität spielen keine Rolle, es geht schließlich um alles: um das eigene
                Kind. Die Fronten in diesem Kampf sind klar: Frau Müller muss weg. Doch Frau Müller schlägt zurück und die
                Situation eskaliert.
                <br><br>
                Kritik am eigenen Kind bringt Eltern zur Explosion: Der ehrgeizige arbeitslose Vater, das zerstrittene
                Ehepaar mit dem an ADHS leidenden Sohn und die Mutter eines Klassenbesten. Voller Witz und Humor, pointiert
                zugespitzt, mit überraschenden Wendungen zeichnet Lutz Hübner das Bild einer Gesellschaft voller Förderwahn,
                einer Gesellschaft, in der nur noch ein Gymnasialabschluss in Frage kommt.
            </template>
            <template #mitwirkende>
                <v-img src="/Bilder/FrauMuellerMussWeg/mueller_ensemble.png" max-width="800"></v-img>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Mueller",
        components: {BaseStueck},
        metaInfo: {
            title: "Frau Müller",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Frau Müller muss weg'}
            ]
        },
    }
</script>

<style scoped>

</style>