<template>
    <div>
        <base-stueck
                name="Hamlet"
                path-name="Hamlet"
                title="Jeder ist Hamlet oder Der bestrafte Brudermord"
                subtitle="garantiert nicht (aber doch manchmal)"
                author="William Shakespeare"
                :bilder-anzahl="6"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Prinz Hamlets Vater stirbt plötzlich am Schlagfluss. Kurz darauf wird sein Onkel König und heiratet Hamlets
                Mutter. Doch der tote Vater erscheint Hamlet als Geist und offenbart ihm, er sei "durch seines Bruders Hand
                ums Leben gekommen". Hamlets Welt ist aus den Fugen, er sinnt auf Rache.<br/>
                Der besondere Reiz des Stückes liegt in der Tat darin, dass alle Spieler/-innen mal den Hamlet geben. Und
                dar- über hinaus noch mindestens zwei, drei andere Rollen.
            </template>
            <template #mitwirkende>
                <strong>Es spielten:</strong><br/>
                Klaus Busskamp<br/>
                Mario Hänel<br/>
                Gerlinde Köstler-Dietl<br/>
                Doris von Krusenstjern<br/>
                Heidi Rohde<br/>
                Anna Scharlipp<br/>
                Gabriele Scheuchenpflug <br/>
                Ulrike Schiemenz<br/>
                Dieter Zembsch<br/><br/>
                <strong>Musik:</strong> Paul Rohde, Tiziano Seifert<br/><br/>
                <strong>Regie:</strong> Hans-Christian Hinterberger
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Hamlet",
        components: {BaseStueck},
        metaInfo: {
            title: "Hamlet",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Jeder ist Hamlet'}
            ]
        },
    }
</script>

<style scoped>

</style>