<template>
    <div>
        <v-col cols="12" lg="6">
            <v-card>
                <v-form ref="contactForm"
                        class="ajax_form"
                        name="contactForm"
                        @submit.prevent="submitKontakt">
                    <v-card-text>
                        <v-text-field dense filled
                                      validate-on-blur
                                      :rules="[
                                                  v => !!v || 'Name wird benötigt',
                                                  ]"
                                      label="Ihr Name:" name="yourname" v-model="name" id="name"/>

                        <v-text-field dense filled
                                      validate-on-blur
                                      :rules="[
                                                  v => !!v || 'Mail wird benötigt',
                                                  v => /^\w[\w|\.|\-]+@\w[\w|\.|\-]+\.[a-zA-Z]{2,4}$/.test(v) || 'Keine E-Mail'
                                                  ]"
                                      label="Ihre E-Mail Adresse:" name="email" v-model="mail"
                                      id="email"/>

                        <v-text-field dense filled
                                      validate-on-blur
                                      :rules="[
                                                  v => !!v || 'Betreff wird benötigt',
                                                  ]"
                                      label="Betreff:" name="subject" v-model="subject" id="subject"/>
                        <v-textarea
                                dense
                                filled
                                label="Ihre Nachricht:"
                                id="message" name="message"
                                v-model="message"
                                no-resize
                                :rules="[
                                          v => !!v || 'Nachricht wird benötigt',
                                          ]"
                                cols="20"
                                rows="10"></v-textarea>
                        <v-checkbox
                                dense
                                label="Newsletter abonnieren"
                                id="newsletter"
                                name="newsletter"
                                v-model="newsletter"
                        ></v-checkbox>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text
                                   @click.stop="reset">Zur&uuml;cksetzen
                            </v-btn>
                            <v-btn small name="Send"
                                   id="send" type="submit">Nachricht senden
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-col>
        <v-snackbar multi-line :timeout="snackbarColor === 'success' ? 20000 : 0" v-model="snackbar"
                    :color="snackbarColor">
            {{snackbarText}}
            <v-btn
                    dark
                    text
                    @click="snackbar = false">X
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: "Kontakt",
        metaInfo: {
            title: "Kontakt",
            meta: [
                {name: 'description', content: 'Hier können Sie uns kontaktieren'}
            ]
        },
        data() {
            return {
                name: "",
                mail: "",
                subject: "",
                message: "",
                newsletter: false,

                snackbar: false,
                snackbarText: "",
                snackbarColor: "success"
            }
        },
        methods: {
            reset() {
                this.$refs.contactForm.reset()
            },

            submitKontakt() {
                if (this.$refs.contactForm.validate()) {
                    var formData = new FormData();
                    formData.append('yourname', this.name);
                    formData.append('email', this.mail);
                    formData.append('subject', this.subject);
                    formData.append('message', this.message);
                    formData.append('newsletter', this.newsletter);

                    fetch('./sendmail.php', {
                        method: 'post',
                        body: formData
                    }).then(result => {
                        if (!result.ok) {
                            throw new Error();
                        }
                        result.json()
                            .then(value => {
                                var message = "Nachricht erfolgreich versendet.";
                                if (value.message !== undefined) {
                                    message = value.message;
                                }
                                this.snackbarColor = "success";
                                this.snackbarText = message;
                                this.snackbar = true;
                            });
                        this.reset();
                    }).catch(() => {
                        this.snackbarColor = "error";
                        this.snackbarText = "Nachricht konnte nicht gesendet werden. Bitte melden Sie sich bei uns direkt via kontakt@theater-heidekraut.de!";
                        this.snackbar = true;
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>