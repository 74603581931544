<template>
    <div>
        <base-stueck
                name="Duerrenmatt"
                path-name="Duerrenmatt"
                title="Die Physiker"
                author="Dürrenmatt"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Was macht ein Forscher, wenn er nach einer grandiosen, zugleich gefährlichen Entdeckung plötzlich die
                Verantwortung für die Welt spürt? Gibt er, bedrängt von allen, trotzdem sein Wissen preis? Verschweigt
                er
                es? Oder flüchtet er sich in eine (gespielte) psychische Erkrankung?<br/>
                Die düstere Komödie von 1961 ist gerade im Hinblick auf Tschernobyl und Fukushima sowie die weitere
                Erforschung der Möglichkeiten in der Gen- und Datentechnik von hoher Aktualität.
            </template>
            <template #mitwirkende>
                <strong>Es spielten:</strong><br/>
                Simon von der Au<br/>
                Florian Bleikert / Silke Bleikert<br/>
                Heike Hänel<br/>
                Veronika Herrmann<br/>
                Doris von Krusenstjern<br/>
                Gerhard Peipp<br/>
                Heidi Rohde / Paul Rohde <br/>
                Gabriele Scheuchenpflug<br/><br/><br/>
                <strong>Regie:</strong> Gudrun Skupin
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Duerrenmatt",
        components: {BaseStueck},
        metaInfo: {
            title: "Duerrenmatt",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Die Physiker'}
            ]
        },
    }
</script>

<style scoped>

</style>