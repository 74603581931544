<template>
    <div>
        <base-stueck
                name="1000"
                path-name="1000"
                title="Ein Grand-Guignol- Abend mit fünf Einaktern"
                author="Paul Antier, Paul Cloquemin, Joubert, Chrales Hellen, W. Valcros, Paul D' Estoc, André de Lorde, Charles Foley"
                :bilder-anzahl="7"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Grand-Guignol bezeichnet im Theater eine spezielle Gattung von grotesk-trivialen Grusel- und
                Horror-stücken.
                In jedem der fünf Einakter wird (mindestens) eine Person auf scheußlichste Weise ermordet. Die Motive
                sind
                mal Eifersucht, Geld- und Machtgier oder schlicht Irrsinn.<br/><br/>
                Doch der Spaß kommt nicht zu kurz. Die Spieler nämlich springen, kaum dass sie gemeuchelt wurden, nach
                dem
                letzten Satz auf, um sich nach raschem Kostümwechsel in ein neues blutiges Drama zu stürzen.
            </template>
            <template #mitwirkende>
                <strong>Es spielten:</strong><br/>
                Christian Ahrens<br/>
                Simon von der Au<br/>
                Silke Bleikert / <br/>
                Klaus Busskamp <br/>
                Margret Kipphan<br/>
                Doris von Krusenstjern<br/>
                Gerhard Peipp / <br/>
                Heidi Rohde<br/>
                Paul Rohde<br/>
                Gabriele Scheuchenpflug<br/>
                Diana Schnell<br/>
                Dieter Zembsch<br/><br/>
                <strong>Musik:</strong><br/> Hans-Christian <br/>Hinterberger<br/><br/>
                <strong>Regie:</strong><br/>
                Gudrun Skupin
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "1000",
        components: {BaseStueck},
        metaInfo: {
            title: "1000 Tode",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Ein Grand-Guignol- Abend mit fünf Einaktern'}
            ]
        },
    }
</script>

<style scoped>

</style>