<template>
    <div>
        <base-stueck
                name="Luther"
                path-name="Luther"
                title="Martin Luther und Thomas Münzer oder Die Einführung der Buchhaltung"
                author="Dieter Forte"
                bilder-format="png"
        >
            <template #beschreibung>
                Eine <b>szenische Lesung</b> der Theatergruppe Heidekraut.<br>
                <p class="body-1">
                    <b>E</b>in Knaller! Verblüffend und irritierend!<br><br>

                    <b>R</b>eformationszeit, 1514 – 1525. Universelle Charaktere in
                    einem Stück aus Originaltexten mit politischem und humoristischem
                    Explosionspotenzial!<br><br>

                    <b>D</b>ie Mechanismen der Macht und die Verquickung von Wirtschaft, Politik und Kirche –
                    vorangetrieben durch die Global-Player – sind ein Spiegelbild unserer Gegenwart:
                    Bankenkrisen,
                    Staatsbankrotte, die zerstörerischen Auswirkungen des wirtschaftlichen Ungleichgewichts in
                    der
                    Gesellschaft auf die Solidargemeinschaft.<br><br>

                    <b>D</b>as Stück stellt jede Satiresendung in den Schatten!<br><br>

                    <b>Zitate:</b><br>
                    „Schwarmgeisterei.“ - <cite>M. Luther</cite> <br>
                    „Volkstheater.“ - <cite>A. Karlstadt</cite> <br>
                    „Ich will das Monopol.“ - <cite>J. Fugger</cite> <br>
                    „Dann wähle ich die Vernunft.“ - <cite>Ph. Melanchthon</cite> <br>
                    „Ich arbeite ohne Tricks.“ - <cite>Th. Münzer</cite> <br>
                    <br><br>

                    „Dass die Bezüge auf unsere Zeit so klar und unübersehbar sind, hat mich selbst überrascht.
                    Es bedurfte keiner Aktualisierung … . Es gibt anscheinend Konstellationen, die
                    sich modellhaft wiederholen.“ <br>
                    - <cite>Dieter Forte</cite><br><br>
                </p>
            </template>
            <template #mitwirkende>
                <v-img src="/Bilder/Luther/Luther-darsteller.png" width="100%"></v-img>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Luther",
        components: {BaseStueck},
        metaInfo: {
            title: "Luther",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Martin Luther und Thomas Münzer'}
            ]
        },
    }
</script>

<style scoped>

</style>