<template>
    <v-row>
      <v-col cols="12" lg="5" class="text-end">
        <h3 class="font-italic font-weight-light">Ein Gefühl,<br/>
          das kaum zu beschreiben<br/>
          und durch nichts anderes<br/>
          zu ersetzen ist...
        </h3>
      </v-col>
      <v-col cols="12" lg="5">
        <h4>Theatergruppe Heidekraut e.V.</h4><br/>
        <p class="body-1"><strong>Wer sind wir?</strong></p><br>
        <p class="body-1">Wir sind begeistert vom Theater und jedes Lächeln im Publikum treibt uns an.</p>
        <br/>
        <p class="body-1"><strong>Was machen wir?</strong></p><br>
        <p class="body-1">Neben aktuellen Produktionen verschiedenster Theaterstücke machen wir:</p>
        <ul style="list-style-type:square;">
            <li><p class="body-1">Schauspieltraining</p></li>
            <li><p class="body-1">Sprechtraining</p></li>
            <li><p class="body-1">uvm...</p></li>
        </ul>
        <br/>
        <br>
        <strong>Nehmen Sie doch <router-link to="/Kontakt">KONTAKT</router-link> mit uns auf!</strong>
      </v-col>
    </v-row>
</template>

<script>

    export default {
        name: "Verein",
        metaInfo: {
            title: "Verein",
            meta: [
                {name: 'description', content: 'Details zu unserem Verein'}
            ]
        },
    }
</script>

<style scoped>

</style>