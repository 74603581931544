<template>
    <v-row>
        <v-col cols="12" lg="5" class="text-end">
            <h3 class="font-italic font-weight-light">...was wird – <br/>
                oder werden kann</h3>
        </v-col>
        <v-col cols="12" lg="5">
            <p class="body-1">Gedanken (-spielereien), Ausblicke:</p><br/>
            <p class="body-1">
                - "Ägypten / Hunger und Durst" - Ein Stück ohne Worte<br>
                - "Herr Mauz"<br>
                - "Der zerbrochne Krug"<br>
                - "Macbeth"<br/>
                - "Faust I"<br/>
                - Interaktives Theater<br>
                - fechten lernen für die Bühne<br/>
                - Barocktanz<br/>
                - "King Kongs Töchter"<br/>
                - griechische Tragödien<br>
                – Sprechchor<br>
                .....<br/>
            </p>
        </v-col>
    </v-row>
</template>

<script>

    export default {
        name: "wasWird",
        metaInfo: {
            title: "Was wird",
            meta: [
                {name: 'description', content: 'Stücke und Ideen, die aktuell in Aussicht stehen'}
            ]
        }
    }
</script>

<style scoped>

</style>