<template>
    <div>
        <base-stueck
                name="Schaden"
                path-name="Unterhaltungsschaden"
                title="unterHALTUNGSSCHADEN"
                subtitle=" - ein Varieté für Arbeitnehmer"
                :bilder-anzahl="12"
                :has-flyer="false"
                :has-plakat="false"
        >
            <template #beschreibung>
                Arbeitsalltag. Eine Bürogemeinschaft. Kaffee und Karriere. Ankommen am Schreibtisch, durchatmen und
                loslegen.
                Irgendjemand erzählt Witze, die Kollegen werden zum Chef gerufen. Einer nach dem Anderen. Jeder ist
                gut
                vorbereitet, jeder möchte etwas erreichen, jeder weiss sich zu profilieren und zu verkaufen. Jeder
                will
                etwas für sich in seinem Leben.<br>
                Nur was? Wie bringen wir das Leben im Büro in Einklang mit unseren privaten und politischen
                Leitbildern. Gibt
                es überhaupt noch die Trennung von Privatleben und Arbeit, von Job und Lifestyle? Ist die Firma
                meine
                Familie? Welche Auswirkungen hat die Wirtschaftskrise auf meine Work-Life-Balance? Verwirkliche ich
                mich
                durch meinen Beruf oder ist das Selbstausbeutung? Steuert am Ende der Kapitalismus mein
                Entspannungs- und
                Unterhaltungsbedürfnis? Die Theatergruppe ‚Heidekraut' geht diesen Fragen auf den Grund.
                ‚unterHALTUNGSSCHADEN' ist eine Hommage an den täglichen Wahnsinn im Bürozirkus. Dabei ist ein
                absurd-komischer Abgesang auf Selbstoptimierungsstrategien im Zeitalter ‚flacher Hierarchien'
                entstanden.
            </template>
            <template #mitwirkende>
                <p class="body-1"><br/>
                    <strong>Regie: </strong>Anna Winde-Hertling<br/><br/>
                    <strong>Assistenz:</strong> Otone Sato<br/><br/>
                    <strong>Es spielten:</strong>
                    Christian Ahrens, Ursula Baier, Gerlinde Köster-Dietl, Doris Krusenstjern, Barbara Kuklinksi, Thomas
                    Loichinger, Heidi Rohde, Ulrike Schiemenz. <br>
                    <br>
                    <strong>Organisation:</strong> Gerhard Peipp <br>
                    <br>
                    <strong>Licht und Ton:</strong> Mario Hänel</p>
                <p class="body-1"><strong>Dank an:</strong> Hans-Christian Hinterberger, Gudrun Skupin, Mirco Winde, Dieter Zembsch.
                </p>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Unterhaltungsschaden",
        components: {BaseStueck},
        metaInfo: {
            title: "Unterhaltungsschaden",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Unterhaltungsschaden'}
            ]
        },
    }
</script>

<style scoped>

</style>