<template>
    <div>
        <base-stueck
                name="Gerald"
                path-name="Gerald"
                title="Geralds Tod"
                author="Andreas Schäfer"
                :bilder-anzahl="21"
        >
            <template #beschreibung>
                Herr und Frau Hofbauer – ein evangelischer Theologe und seine treusorgende Gattin – finden
                ihren eigenen
                Sohn
                Gerald derart unsympathisch, dass sie den 18-jährigen nach Abwägen guter Gründe hinterrücks
                mit einem
                Brotmesser
                töten. Nach der Tat plagen Frau Hofbauer jedoch Gewissensbisse und sie kann sich nicht
                entschließen, die
                Leiche
                ihres Sohnes aus dem Zimmer zu räumen. Um Geralds Leiche entwickeln sich nun groteske
                Szenen, in denen die
                Sprache den Menschen mehr zu beherrschen scheint als umgekehrt der Mensch die Sprache. Dabei
                degenerieren
                die
                Begegnungen mit Gerald, dessen Tod und Leiche in den sieben Akten des Stückes immer wieder
                zur
                Nebenhandlung.
                <br><br>
                Geralds Tod ist eine innovative Mischform aus Komödie und absurder Farce.

                <br><br>
            </template>
            <template #mitwirkende>
                <v-img src="/Bilder/Gerald/gerald-ensemble.png" max-width="400"></v-img>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Gerald",
        components: {BaseStueck},
        metaInfo: {
            title: "Geralds Tod",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Geralds Tod'}
            ]
        },
    }
</script>

<style scoped>

</style>