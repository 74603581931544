<template>
    <div>
        <base-stueck
                name="London_Suite"
                path-name="LondonSuite"
                title="London Suite"
                author="Neil Simon"
                :bilder-anzahl="11"
        >
            <template #beschreibung>
                Vier Einakter, die in einer Suite eines feinen britischen Hotels spielen.
                Bös-bissige Wortgefechte, anrührende Momente, hochdramatische Szenen und absurde
                Slapstick kenn- zeichnen dieses Stück des US-amerikanischen Erfolgsautors.
                Der Interviewtermin wird für eine Romanautorin schier zum Horrortrip
                („Die Buch- vorstellung“). Eine Mutter offenbart mitten in der Nacht ihrer Tochter ein gut
                gehütetes Geheimnis („Going Home“). Durch die Begegnung mit ihrem Ex-Mann erfährt das
                Leben einer berühmten Sitcom-Darstellerin eine ungeahnte Wendung („Diana & Sidney“).
                Und die verzweifelte Suche nach verloren gegangenen Wimbledonkarten endet spektakulär
                mit zwei Bandscheibenvorfällen („Der Mann am Boden“). Umrahmt wird das Geschehen von
                der Chefin des Hauses, Mrs. Sitgood, ihren Angestellten und dem Pianisten in der Hotelbar.
            </template>
            <template #mitwirkende>
                <strong>Es spielten:</strong><br>
                Ursula Baier<br>
                Josefine Boldewin<br>
                Sophia Carvalho-Loichinger<br>
                Burkhard Frerig-Liekhues<br>
                Mario Hänel<br>
                Hans-Christian Hinterberger<br>
                Gerlinde Köstler-Dietl<br>
                Doris von Krusenstjern <br>
                Diana van der Linden <br>
                Thomas Loichinger <br>
                Heidi Rohde <br>
                <br><br>
                <strong>Regie:</strong><br>
                Gudrun Skupin<br>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "LondonSuite",
        components: {BaseStueck},
        metaInfo: {
            title: "London Suite",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: London Suite'}
            ]
        },
    }
</script>

<style scoped>

</style>