<template>
  <v-row>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Gudrun"
                   image-src="/Bilder/Akteure/Gudrun.JPG"
      >Neugier, Leidenschaft, Spaß, Jubel, Konzentration, Entwicklung, Klänge und Geräusche,
        Reibung,
        das
        Drumrum, Energie, Applaus, Atem und Bewegung. Extreme: Lieben und hassen. Begehren und
        töten.
        Vertrauen und verraten.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Hans-Christian"
                   image-src="/Bilder/Akteure/Hans_Christian.JPG"
      >Sein können, was man sonst nicht sein darf.<br>
        Sein dürfen, was man sonst nicht sein kann.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Jana"
                   image-src="/Bilder/Akteure/Jana.jpg"
      >Ich spiele gerne Theater, weil ich dort...
        böse, skurill, dumm, verzweifelt, lustig, schön, hässlich, angeberisch, ironisch, sarkastisch,
        verliebt, gewitzt, lächerlich und vieles mehr sein kann.<br>
        Alles was ich im realen Leben nicht immer sein darf.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Ronaro"
                   image-src="/Bilder/Akteure/Ronaro.JPG"
      > Mit Theater bringe ich Leute zum Lachen und zum Weinen, aber vor allem zum Denken ...
        ohne viel erklären zu müssen.<br>
        Mit Theater kann ich lieben und geliebt werden, töten und getötet werden ...
        danach gehe ich schlafen, und morgen geht alles wieder von vorne los.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Anschi"
                   image-src="/Bilder/Akteure/Anschi.png"
      >"Wer A sagt, der muss nicht B sagen. Er kann auch erkennen, dass A falsch war." (Brecht) <br>
        Im Theaterprozess zu erkennen, das A tatsächlich oft falsch ist,
        ist zugleich depremierend, spannend, nervenaufreibend und einfach wunderschön!
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Carsten"
                   image-src="/Bilder/Akteure/Carsten.jpg"
      >Theater ist ein vielseitiges, anspruchsvolles und verführerisches Spiel im Team....<br>
        mit anerkennendem Applaus als Belohnung.
        Auf der Bühne ist alles erlaubt, niemand versteckt sich.
        Man kann multiple, verrückte Seiten seiner Persönlichkeit ausleben,
        ohne dass man gleich für irre erklärt wird. Das ist grossartig!
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Constanze"
                   image-src="/Bilder/Akteure/Constanze.jpg"
      > Ich spiele Theater seit ich klein bin und wüsste nicht, warum ich jemals damit aufhören sollte.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Sabine"
                   image-src="/Bilder/Akteure/Sabine.JPG"
      >Theater ist für mich mehr als ein Hobby!
        Es ist mehr eine "Lebensweise", eine Welt, in der ich alles ausprobieren kann.<br>
        Es ist eine Art, mich ausdrücken zu können,
        die ich nirgendwo anders finden kann! Ich liebe es, mich mit Theater zu beschäftigen,
        vor, auf, hinter und unter (!?!) der Bühne...
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Gerlinde"
                   image-src="/Bilder/Akteure/placeholder_woman.png"
      >Theater ist gespiegeltes Leben.
        Um das Dabeisein, um das Miteinander geht es mir. Mitzuerleben, wie ein Stück "wird", wie es
        allmählich
        Gestalt annimmt. Von der Textvorlage bis hin zur Aufführung.
        Dieser so spannende Prozeß ist es.
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Ursula"
                   image-src="/Bilder/Akteure/placeholder_woman.png"
      > Rollen von skurril bis normal, lustig bis traurig, gut bis böse, ehrlich bis scheinheilig,
        sanft
        bis brutal, brav bis verrückt:
        Einfach alle Lebensfacetten mit Spaß in der Gruppe durchspielen und erleben!
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Christian"
                   image-src="/Bilder/Akteure/placeholder_man.png"
      >Weil auch eine multiple Persönlichkeit ein entsprechendes Hobby braucht und man sich unter
        Gleichgesinnten einfach besser verstanden fühlt...
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Gerhard"
                   image-src="/Bilder/Akteure/placeholder_man.png"
      >
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Andreas"
                   image-src="/Bilder/Akteure/placeholder_man.png"
      >
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Christin"
                   image-src="/Bilder/Akteure/placeholder_woman.png"
      >
      </akteur-card>
    </v-col>
    <v-col cols="12" lg="6">
      <akteur-card class="mt-2"
                   name="Claudia"
                   image-src="/Bilder/Akteure/placeholder_man.png"
      >
      </akteur-card>
    </v-col>
  </v-row>
</template>

<script>
import AkteurCard from "./AkteurCard";

export default {
  name: "AkteurePassiv",
  components: {AkteurCard}
}
</script>

<style scoped>

</style>