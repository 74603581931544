<template>
    <div>
        <base-stueck
                name="InfizierendeGefuehle"
                path-name="InfizierendeGefuehle"
                title="Infizier…ende Gefühle"
                subtitle="Eine schräge Collage, angereichert mit unglaublichen Irritationen des Lebens und einer gehörigen Portion Wahnsinn"
                :bilder-anzahl="108"
        >
            <template #beschreibung>
                Wir rücken mit deutlichen und nicht-deutlichen Worten dem Rassismus
                zu Leibe, verschieben Grenzen, brechen Tabus, verblüffen und faszinieren
                mit berührenden, verstörenden, albernen und originell-eigenwilligen
                „infizier…enden“ Gefühlen. <br>
                Wie Albert Einstein schon sagte: <br>
                „Die reinste Form des Wahnsinn ist es,
                alles beim Alten zu belassen und zu hoffen, dass sich etwas ändert.“
                <br><br>
                Wir hoffen Sie waren dabei!
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "InfizierendeGefuehle",
        components: {BaseStueck},
        metaInfo: {
            title: "Infizierende Gefuehle",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Infizierende Gefuehle'}
            ]
        },
    }
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  font-size: small;
  padding: 8px;
}

tr:first-child {
  background-color: #eee;
}
</style>