<template>
    <div>
        <base-stueck
                name="Ungleiches_Paar"
                path-name="UngleichesPaar"
                title="Ein ungleiches Paar"
                author="Neil Simon"
                :bilder-anzahl="9"
        >
            <template #beschreibung>
                "Wenn man sich umbringen will, wo tut man das am besten? Bei den Freundinnen, " sagt sich Florence,
                nachdem ihr Mann sich nach 20 Jahren scheiden lassen will und wirbelt damit die gesellige
                Trivial-Pursuit-Runde ihrer Freundinnen gründlich durcheinander. Olivia nimmt Florence sofort
                auf - eine Entscheidung, die Olivia bald bereut, denn die neu gegründete Frauen-WG entpuppt sich
                als eheliches Panoptikum. Neben Staubsauger, Putzlappen, Bio-Luftionisator hat Florence alle auf
                dem Markt erhältlichen Desinfektionssprays dabei und beginnt Olivias Wohnung aufzupeppeln. So werden
                aus den wunderbar chaotischen Trivial-Pursuit-Runden mit ungenießbaren Chips keimfreie Dinnerpartys.
                Als Florence Olivias Date mit zwei heißblütigen Spaniern sprichwörtlich in Tränen ertränkt, sieht
                Olivia endgültig rot.<br><br>
                Die Verfilmung dieses Stoffes gelang zum Weltruhm - die Bühnenfassung "Ein ungleiches Paar"
                ist die weibliche Antwort und hinterfragt mit viel Wortwitz und Situationskomik die Themen
                einer Freundschaft. Die Schwächen und Hässlichkeiten, die Aristoteles einst an einer Komödie
                monierte, werden hier zum Trainingsgerät für die Lachmuskulatur.
            </template>
            <template #mitwirkende>
                <v-img src="/Bilder/UngleichesPaar/Ungleiches_Paar_Mitwirkende.png" max-width="500"></v-img>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "UngleichesPaar",
        components: {BaseStueck},
        metaInfo: {
            title: "Ungleiches Paar",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Ein ungleiches Paar'}
            ]
        },
    }
</script>

<style scoped>

</style>