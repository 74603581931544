<template>
    <div>
        <base-stueck
                name="Yvonne"
                path-name="Yvonne"
                title="Yvonne, die Burgunderprinzessin"
                author="Witold Gombrowicz (1904 - 1969)"
                :bilder-anzahl="19"
                :has-flyer="false"
        >
            <template #beschreibung>
                Prinz Philipp fühlt sich von Yvonnes Schweigen, ihrem Phlegma derart
                    „angezogen“, dass er beschließt, sie zu heiraten. Um einen Skandal am
                    Hof zu vermeiden, willigen König und Königin in die Hochzeit ein. Man
                    muss die unscheinbare, bürgerliche junge Frau lediglich hof- und
                    gesellschaftsfähig machen, dann wird schon alles gut.<br><br>
                Doch die Versuche dahin scheitern an Yvonnes vollkommener Passivität.
                    Es geschieht eher das Gegenteil: Nicht Yvonne, alle anderen verändern
                    sich – provoziert allein durch ihre Anwesenheit. So wird ein bislang
                    gut gehütetes Geheimnis der Königin entdeckt. Der König und der Kammerherr
                    erinnern sich an ein Verbrechen, dass sie vor Jahren begangen haben.
                    Jeder scheint jeden zu belauern, überall wispert es. Die alte,
                    funktionierende Ordnung am Hof droht zu zerfallen. König und Königin
                    ist klar: Das muss verhindert werden! Und es gibt nur einen Ausweg … .
            </template>
            <template #mitwirkende>
                <v-img src="/Bilder/Yvonne/Yvonne-darsteller.png" width="90%"></v-img>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Yvonne",
        components: {BaseStueck},
        metaInfo: {
            title: "Yvonne",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Yvonne die Burgunderprinzessin'}
            ]
        },
    }
</script>

<style scoped>

</style>