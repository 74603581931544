<template>
    <v-row>
        <v-col cols="12" lg="5" class="text-end">
            <h3 class="font-italic font-weight-light">Ich glaube an die <br/>
                Unsterblichkeit <br/>
                des Theaters.<br/>
                Es ist der seligste Schlupfwinkel für diejenigen, die ihre Kindheit heimlich in die Tasche gesteckt und
                sich
                damit auf und davon gemacht haben,<br/>
                um bis an ihr Lebensende weiterzuspielen.</h3><br/>
            <p class="body-1">Max Reinhardt</p>
        </v-col>

        <v-col cols="12" lg="5">
            <small>Für Details klicken</small>
            <v-row :justify="$vuetify.breakpoint.lgAndUp ? 'start' : 'center'">
                <v-col cols="10" lg="7">
                  <v-hover
                      v-slot:default="{ hover }"
                      open-delay="100"
                  >
                    <v-card
                        :elevation="hover ? 12: 6"
                        ripple>
                      <router-link to="was_war/HelgesLeben">
                        <v-img src="/images/Menu_HelgesLeben.png"
                               gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                               class="white--text align-end"
                               style="border-radius: 4px">
                          <v-card-title class="subtitle-2">Helges Leben</v-card-title>
                        </v-img>
                      </router-link>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                  <v-hover
                      v-slot:default="{ hover }"
                      open-delay="100"
                  >
                    <v-card
                        :elevation="hover ? 12: 6"
                        ripple>
                      <router-link to="was_war/InfizierendeGefuehle">
                        <v-img src="/images/Menu_InfizierendeGefuehle.png"
                               gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                               class="white--text align-end"
                               style="border-radius: 4px">
                          <v-card-title class="subtitle-2">InfizierendeGefuehle</v-card-title>
                        </v-img>
                      </router-link>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                  <v-hover
                      v-slot:default="{ hover }"
                      open-delay="100"
                  >
                    <v-card
                        :elevation="hover ? 12: 6"
                        ripple>
                      <router-link to="was_war/Thunfischdosen">
                        <v-img src="/images/Menu_Thunfischdosen.png"
                               gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                               class="white--text align-end"
                               style="border-radius: 4px">
                          <v-card-title class="subtitle-2">Thunfischdosen</v-card-title>
                        </v-img>
                      </router-link>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                  <v-hover
                      v-slot:default="{ hover }"
                      open-delay="100"
                  >
                    <v-card
                        :elevation="hover ? 12: 6"
                        ripple>
                      <router-link to="was_war/GedaechtnisWasser">
                        <v-img src="/images/Menu_GedaechtnisWasser.png"
                               gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                               class="white--text align-end"
                               style="border-radius: 4px">
                          <v-card-title class="subtitle-2">Gedächtnis des Wasser</v-card-title>
                        </v-img>
                      </router-link>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 3"
                                ripple>
                            <router-link to="was_war/PlemPlem">
                                <v-img src="/images/Menu_PlemPlem.jpg"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Plem Plem</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/UngleichesPaar">
                                <v-img src="/images/Menu_UngleichesPaar.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Ein ungleiches Paar</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Dorian">
                                <v-img src="/images/Menu_Dorian.png" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Das Bildnis von Dorian Gray</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Mueller">
                                <v-img src="/images/Menu_Mueller.jpg"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Frau Müller muss weg</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Gerald">
                                <v-img src="/images/Menu_Gerald.jpg" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Geralds Tod</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Luther">
                                <v-img src="/images/Menu_Luther.png" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Martin Luther & Thomas Münzer</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Yvonne">
                                <v-img src="/images/Menu_Yvonne.png" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Yvonne - Die Burgunderprinzessin</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/London_Suite">
                                <v-img src="/images/Menu_London_Suite.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">London Suite</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Geister">
                                <v-img src="/images/Menu_Geister.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Haltestelle Geister</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Unterhaltungsschaden">
                                <v-img src="/images/Menu_Schaden.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Unterhaltungsschaden</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Sommernachtstraum">
                                <v-img src="/images/Menu_Sommernachtstraum.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Sommernachtstraum</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Hamlet">
                                <v-img src="/images/Menu_Hamlet.png" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Jeder ist Hamlet</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Rosenbeet">
                                <v-img src="/images/Menu_Rosenbeet.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Schau nicht unters Rosenbeet</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/1000">
                                <v-img src="/images/Menu_1000.png" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">1000 Tode</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Duerrenmatt">
                                <v-img src="/images/Menu_Duerrenmatt.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Die Physiker</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="10" lg="7">
                    <v-hover
                            v-slot:default="{ hover }"
                            open-delay="100"
                    >
                        <v-card
                                :elevation="hover ? 12: 6"
                                ripple>
                            <router-link to="was_war/Wahnsinn">
                                <v-img src="/images/Menu_Wahnsinn.png"
                                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                       class="white--text align-end"
                                       style="border-radius: 4px">
                                    <v-card-title class="subtitle-2">Der nackte Wahnsinn</v-card-title>
                                </v-img>
                            </router-link>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>

    export default {
        name: "was_war",
        metaInfo: {
            title: "Was war",
            meta: [
                {name: 'description', content: 'Alle Stücke, die wir schon aufgeführt haben'}
            ]
        }
    }
</script>

<style scoped>

</style>