<template>
    <div>
        <base-stueck
                name="Thunfischdosen"
                path-name="Thunfischdosen"
                title="Thunfischdosen"
                subtitle=" – und andere heikle Beziehungen"
                :bilder-anzahl="21"
        >
            <template #beschreibung>
              Die Figuren geben fokussiert Einblick in ihre äußerst unterschiedlichen Lebensgeschichten.
              Zwar gelöst aus dem Kontext des jeweiligen Stücks, eint die Figuren ein Problem:<br>
              <strong>Ihre heikle Beziehung.</strong>
              <br><br>
              Sei es zum Chef, zur Macht, zur Rivalin, zum Stalker oder eben zu
              Thunfischdosen. Da läuft es bei niemandem rund. Alle stecken ordentlich
              in ihrem ganz persönlichen, teils irrwitzigen, teils tragischen Schlamassel.
              Es wird geschimpft, gelacht, gehadert, mit einer Pistole hantiert, dunkle Kräfte werden
              geweckt. Und – es werden Entscheidungen getroffen.
              <br><br>
              Monologe aus „Macbeth“ / „Maria Stuart“ / „Schonzeit“ / „Top Dogs“ / „Gebrüllt vor Lachen“
            </template>
            <template #mitwirkende>
              <v-row>
                <v-col cols="12" lg="6">
                  <b style="color: #c00">Besetzung: </b><br><br>
                  <table>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Moderation</td>
                      <td>D. v. Krusenstjern</td>
                    </tr>
                    <tr>
                      <td>Lady Macbeth ("Macbeth" von W. Shakespeare)</td>
                      <td>Y. Thoma</td>
                    </tr>
                    <tr>
                      <td>Königin Elisabeth I. ("Maria Stuart" von F. Schiller)</td>
                      <td>H. Rohde</td>
                    </tr>
                    <tr>
                      <td>Sie ("Schonzeit" von St. Grob)</td>
                      <td>N. Fischer</td>
                    </tr>
                    <tr>
                      <td>Herr Krause ("Top Dogs" von U. Widmer)</td>
                      <td>Th. Loichinger</td>
                    </tr>
                    <tr>
                      <td>Die Frau ("Gebrüllt vor Lachen" von Ch. Durang) </td>
                      <td>D. v. Krusenstjern</td>
                    </tr>
                  </table>
                </v-col>
                <v-col cols="12" lg="6">
                  <b style="color: #c00">Mitwirkende: </b><br><br>
                  <table>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Regie</td>
                      <td>G. Skupin</td>
                    </tr>
                    <tr>
                      <td>Licht</td>
                      <td>M. Hänel</td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </template>
        </base-stueck>
    </div>
</template>

<script>

    import BaseStueck from "../../components/waswar/BaseStueck";

    export default {
        name: "Thunfischdosen",
        components: {BaseStueck},
        metaInfo: {
            title: "Thunfischdosen",
            meta: [
                {name: 'description', content: 'Details zu unserer Produktion: Thunfischdosen'}
            ]
        },
    }
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  font-size: small;
  padding: 8px;
}

tr:first-child {
  background-color: #eee;
}
</style>